import * as React from 'react';


function RAV() {
    
return (
  <div className="iframe-container">
  <iframe
      src="https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/637423293370/dashboards/1f4253c4-c2b4-48d9-8784-63af60f970ad?directory_alias=ccoe-opmetrics"
      title="Embedded Dashboard"
  ></iframe>
</div>
  ); 
}
export default RAV;
