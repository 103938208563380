import { Fragment, useState } from "react";
import { VerticalNavbar, setActiveValueInNavItems } from "@nike/eds";
import{useNavigate} from 'react-router-dom';



export default function Navigation() {

    const navigate =useNavigate();
    // Array of NavItems & NavSubItems & BottomItems
    const [items, setItems] = useState([
        {
            id: "1",
            icon: "Home",
            label: "Home",
            active: false,
        },
        {
            id: "2",
            icon: "Desktop",
            label: "Compute",
            active: false,
        },
        {
            id: "3",
            icon: "Payment",
            label: "FinOps",
            active: false,
            },
        {
            id: "4",
            icon: "Bag",
            label: "Storage",
            active: false,
        },
        {
            id: "5",
            icon: "Rewards",
            label: "Cloud Governance",
            active: false,
        },
    
    ]);
    const [bottomItems] = useState([
     
    ]);

    // const [visible, setVisible] = useState(false);
    // Click event function example that sets the active value for a nav item
    // Updates the items array with the new items with active state
    const handleClick = (item) => {
        if (item.label === "Compute") {
            navigate('/compute');
        }
        if (item.label === "Home") {
            navigate('/');
        }
        if (item.label === "Storage") {
            window.location.href = "https://cloudstorage-test.nike.com/executive";
        }
        if (item.label === "FinOps") {
        window.location.href ="https://dash.finops.nikecloud.com/dashboard/Executive/?sheet=Executive+Overview";
        }
        if (item.label === "Cloud Governance") {
            window.location.href = "https://CloudGovernance.nike.com";
        }
        const newItems = setActiveValueInNavItems(item.id, items);
        setItems(newItems);
    };
    return (
        <Fragment>
        <div id="v-navigation-div">
            <VerticalNavbar
            id="v-nav-bar"
            appName="Operational Metrics"
            onNavigate={handleClick}
            items={items}
            bottomItems={bottomItems}
            />
        </div>
        </Fragment>
    );
}