import * as React from 'react';


function Home() {
    
return (
  <div className="iframe-container">
  <iframe
      src="https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/637423293370/dashboards/63014c6e-fe14-4c72-a658-529090e9c832?directory_alias=ccoe-opmetrics"
      title="Embedded Dashboard"
  ></iframe>
</div>
  ); 
}
export default Home;

