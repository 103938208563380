import * as React from 'react';


function Compute() {
    
return (
    <div className="iframe-container">
      <iframe
        src="https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/637423293370/dashboards/b0128b9f-990d-4d82-84e2-1e0595bea5fb?directory_alias=ccoe-opmetrics"
        title="Embedded Dashboard"
      ></iframe>

    </div>
  ); 
}
export default Compute;