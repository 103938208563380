
import React from 'react';
import { AppHeader, Text, ButtonGroup, Button} from "@nike/eds";
import { useAuthStore, selectIdName } from "@nike/aegis-auth-react";
// import SavedView from './SavedViewModal.js';
/* import PinnedOpportunityModal from './PinnedOppModal.js'; */
import "@nike/eds/dist/index.css";
import{useNavigate} from 'react-router-dom';


function Header() {

    const user = useAuthStore(selectIdName).split(" ")[0];
    const [rav, setRAV] = React.useState(false);
    const navigate =useNavigate();

    const handleRAVClick = () => {

        setRAV(!rav);
        if(!rav && window.location.pathname === '/compute'){
          navigate('/compute/rav');
        }  
        else if(rav && window.location.pathname === '/compute/rav'){
          navigate('/compute');
        }
    }


    const ToolbarPlaceHolder = ({ isDark, count }) => (
             <ButtonGroup>
             <Text font={"display-5"} as={"p"}>Welcome {user}!</Text>
             <span> &nbsp; </span>
                {/* <SavedView/> */}
                {/* <PinnedOpportunityModal/> */}
                <Button variant={rav ? "primary" : "secondary"}  onClick={handleRAVClick} active={rav} title="RAV" size= "small">Resource Account View</Button>
            </ButtonGroup>

      );


      
      return(
    <div>
      <AppHeader
        appName="Nike Cloud Dashboard Hub"
        toolbarSlot={<ToolbarPlaceHolder isDark />}
        color={"black"}
      />


    </div>
    
    );
}

export default Header;

